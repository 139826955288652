<script setup lang="ts">
import { computed, useSlots } from 'vue';

const props = defineProps<{
  noHeader?: boolean;
  noPadding?: boolean;
}>();

const slots = useSlots();

const hasSubtitle = computed(() => !!slots.subtitle);

const computedClasses = computed(() => ({
  'app-view-container--no-header': props.noHeader,
  'app-view-container--no-padding': props.noPadding,
}));
</script>

<template>
  <div class="app-view-container" :class="computedClasses">
    <div class="app-view-container__header">
      <div class="app-view-container__title">
        <slot name="title" />
        <span v-if="hasSubtitle" class="app-view-container__subtitle"
          ><slot name="subtitle"
        /></span>
      </div>
      <div class="app-view-container__actions">
        <slot name="actions" />
      </div>
    </div>
    <div class="app-view-container__body">
      <slot />
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.app-view-container {
  $self: &;

  &__header {
    align-items: center;
    display: flex;
    background-color: theme('color-bg-container');
    border-bottom: 1px solid theme('color-border-primary');
    height: $app-header-height;
    justify-content: space-between;
    padding: $space-xxsmall $space-large;
    position: sticky;
    top: 0;
    z-index: layer('drawer');
  }

  &__title {
    font-size: $font-size-medium;
    line-height: 1;
    font-weight: $font-weight-bold;
  }

  &__subtitle {
    color: theme('color-text-secondary');
    font-weight: $font-weight-regular;
  }

  &__body {
    background-color: theme('color-bg-app');
    min-height: calc(100vh - $app-header-height);
    padding: $space-xlarge $space-xlarge * 2;
  }

  &--no-header {
    #{$self} {
      &__header {
        display: none;
      }
      &__body {
        min-height: 100vh;
      }
    }
  }

  &--no-padding {
    #{$self} {
      &__body {
        padding: 0;
      }
    }
  }
}
</style>
